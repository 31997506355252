<template>
	<div class="opacity-page table">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('index-users.search-by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<button id="buttonUpdate" :disabled="payroll_date.is_locked == 1" class="btn-tbf blue custom" v-bind:class="{square: $resize && $mq.between([781, 1150])}" @click="confirmUpdate()">
							<div class="icon">
								<icon-update />
							</div>
							<div class="loader"></div>
							<div v-if="$resize && ($mq.above(600) && !$mq.between([781, 1150]))" class="text">{{$t('general.update')}}</div>
                    	</button>
						<div v-if="payroll_date.is_locked == 1" class="reset-button disabled">
							<div class="icon"><icon-arrow /></div>
						</div>
						<div v-show="payroll_date.is_locked == 0" class="reset-button dropdown">
							<div class="icon" id="dropdownReset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><icon-arrow /></div>

							<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownReset">
								<div class="dropdown-item" @click="showModal('update', {type: 'reset', from: 'index_clocking'})">
									<div class="text">{{ $t('general.reset') }}</div>
								</div>
							</div>
						</div>

						<div class="download-button dropdown">
							<div class="icon settings" id="dropdownDownload" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><icon-download /></div>

							<div class="dropdown-menu dropdown-menu-right" id="dropdownApplications" aria-labelledby="dropdownApplications">
								<div @click="donwloadPdf()" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.clocking_pdf') }}</div>
								</div>
								<div @click="donwloadXLS()" class="dropdown-item">
									<div class="icon"><icon-xls /></div>
									<div class="text">{{ $t('salary.download.clocking_xls') }}</div>
								</div>
								<div @click="donwloadDailyPdf()" class="dropdown-item">
									<div class="icon"><icon-pdf /></div>
									<div class="text">{{ $t('salary.download.clocking_daily_pdf') }}</div>
								</div>
							</div>
						</div>
						<div class="actions actions-dropdown">
							<div class="actions-page mobile ml-15">
								<button class="btn-tbf grey filter-btn-mobile square" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
									<div class="icon settings"><icon-settings /></div>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(1120)}]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content" :class="[{'contract-25': $resize && $mq.above(1121)}]">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedUsers.length">{{ $t('filters.by_users') }}</span>
							<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find(el => el.id == selectedUsers[0]).name }}</span>
							<span class="text" v-else>{{ selectedUsers.length }} {{ $t('filters.responsibles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
						</button>

						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')">
							</div>

							<template v-for="user in filteredUsers">
								<div class="dropdown-item"  @click.stop="selectOptionFilter('selectedUsers', user.id)" v-bind:class="{active: selectedUsers.includes(user.id)}" :key="'filter-user-' + user.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ user.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedDepartments.length">{{ $t('filters.by_departments') }}</span>
							<span class="text" v-else-if="selectedDepartments.length == 1">{{ filterDepartments.find(el => el.id == selectedDepartments[0]).name }}</span>
							<span class="text" v-else>{{ selectedDepartments.length }} {{ $t('filters.departments_selected') }}</span>

							<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')">
							</div>

							<template v-for="department in filteredDepartments">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedDepartments', department.id)" v-bind:class="{active: selectedDepartments.includes(department.id)}" :key="'filter-department-' + department.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ department.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last-but-one">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkstations.length">{{ $t('filters.by_workstations') }}</span>
							<span class="text" v-else-if="selectedWorkstations.length == 1">{{ filterWorkstations.find(el => el.id == selectedWorkstations[0]).name }}</span>
							<span class="text" v-else>{{ selectedWorkstations.length }} {{ $t('filters.workstations_selected') }}</span>

							<div class="icon-filter" v-if="!selectedWorkstations.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkstations')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkstations" :placeholder="$t('filters.search_workstations')">
							</div>

							<template v-for="workstation in filteredWorkstations">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedWorkstations', workstation.id)" v-bind:class="{active: selectedWorkstations.includes(workstation.id)}" :key="'filter-workstation-' + workstation.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ workstation.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
							<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
							<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
							</div>

							<template v-for="role in filteredRoles">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractType.length">{{ $t('filters.by_contract_type') }}</span>
							<span class="text" v-else-if="selectedContractType.length == 1">{{ filterContractsType.find(el => el.id == selectedContractType[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractType.length }} {{ $t('filters.contract_type_selected') }}</span>

							<div class="icon-filter" v-if="!selectedContractType.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractType')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractsType" :placeholder="$t('filters.search_contract_type')">
							</div>

							<template v-for="contract in filteredContractsType">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractType', contract.id)" v-bind:class="{active: selectedContractType.includes(contract.id)}" :key="'filter-contract-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedContractPeriodType.length">{{ $t('filters.by_contract_period_type') }}</span>
							<span class="text" v-else-if="selectedContractPeriodType.length == 1">{{ filterContractPriodType.find(el => el.id == selectedContractPeriodType[0]).name }}</span>
							<span class="text" v-else>{{ selectedContractPeriodType.length }} {{ $t('filters.contract_period_type_selected') }}</span>

							<div class="icon-filter" v-if="!selectedContractPeriodType.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedContractPeriodType')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchContractPriodType" :placeholder="$t('filters.search_contract_period_type')">
							</div>

							<template v-for="contract in filteredContractPriodType">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedContractPeriodType', contract.id)" v-bind:class="{active: selectedContractPeriodType.includes(contract.id)}" :key="'filter-contract-period-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last-but-one">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedHoursPerDay.length">{{ $t('filters.by_hours_per_day') }}</span>
							<span class="text" v-else-if="selectedHoursPerDay.length == 1">{{ filterHoursPerDays.find(el => el.id == selectedHoursPerDay[0]).name }}</span>
							<span class="text" v-else>{{ selectedHoursPerDay.length }} {{ $t('filters.hours_per_day') }}</span>

							<div class="icon-filter" v-if="!selectedHoursPerDay.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedHoursPerDay')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchHoursPerDay" :placeholder="$t('filters.search_hours_per_day')">
							</div>

							<template v-for="contract in filteredHoursPerDays">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedHoursPerDay', contract.id)" v-bind:class="{active: selectedHoursPerDay.includes(contract.id)}" :key="'filter-hours-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<div class="filter-dropdown dropdown last">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedWorkConditionTypes.length">{{ $t('filters.by_work_condition_types') }}</span>
							<span class="text" v-else-if="selectedWorkConditionTypes.length == 1">{{ filterWorkConditionTypes.find(el => el.id == selectedWorkConditionTypes[0]).name }}</span>
							<span class="text" v-else>{{ selectedWorkConditionTypes.length }} {{ $t('filters.work_condition_types') }}</span>

							<div class="icon-filter" v-if="!selectedWorkConditionTypes.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedWorkConditionTypes')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchWorkConditionTypes" :placeholder="$t('filters.search_work_condition_types')">
							</div>

							<template v-for="contract in filteredWorkConditionTypes">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedWorkConditionTypes', contract.id)" v-bind:class="{active: selectedWorkConditionTypes.includes(contract.id)}" :key="'filter-work-conditions-' + contract.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ contract.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(1121)"/>
						<div v-else-if="$resize && $mq.below(1120)">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>
		<template v-if="loadedTable">
			<div class="container-table data-list" v-if="clocking_data.length > 0">
				<div class="space-left"></div>
				<section class="clocking-section">
					<div class="clocking-table">
						<div class="header-days">
							<div class="column empty"></div>
							<div class="column" v-for="intervalDay in clocking_data[0].days" v-bind:class="[{'week-day': intervalDay.is_weekday == 0}, {'legal-holiday': intervalDay.is_legal_holiday == 1}]">
								<div class="day-name">{{ intervalDay.day_name }}</div>
								<div class="date">{{ intervalDay.day }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_worked_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_weekend_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_night_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_holiday_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_overtime_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_CM_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_CO_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_CED_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_CFP_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_absence_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_suspended_hours') }}</div>
							</div>
							<div class="column total-time">
								<div class="day-name">{{ $t('clocking.total_detachment_hours') }}</div>
							</div>
						</div>
						<div class="data-hours">
							<div class="row-user" v-for="(employeeData, index) in clocking_data">
								<div class="column empty"></div>
								<div class="column" v-for="hour in employeeData.days" v-bind:class="[{'week-day': hour.is_weekday == 0}, {'legal-holiday': hour.is_legal_holiday == 1}]" @click="showModal('clocking', {type: 'clocking', date: payroll_date, name: employeeData.first_name + ' ' + employeeData.last_name, from: 'index_clocking', id: employeeData.id,  model: employeeData.days})">
									<div class="total_hours" v-if="hour.display_value">{{ hour.display_value }}</div>
									<div class="total_hours" v-else></div>
									<div class="interval" v-if="hour.start_hour && $mq != 'mobile'">{{ hour.hours_per_day }}</div>
									<div class="interval" v-else></div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_worked_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_weekend_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_night_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_holiday_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_overtime_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_CM_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_CO_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_CED_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_CFP_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_absence_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_suspended_hours }}</div>
								</div>
								<div class="column total-time">
									<div class="total_hours">{{ employeeData.total_detachment_hours }}</div>
								</div>
							</div>
						</div>
						<div class="employees-names">
							<div class="column">
								<div class="title-list"></div>
							</div>
							<div class="column" v-for="employee in clocking_data">
								<div class="employee-name">
									{{ employee.first_name + ' ' + employee.last_name }}
								</div>
								<div v-if="employee.marca" class="description">{{$t('salary.contract-data.mark') + ': ' + employee.marca}}</div>
							</div>
						</div>
					</div>
				</section>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-clocking v-else/>
	</div>
</template>

<script>
    import IconSearch from '../../../Icons/Search'
	import IconUpdate from '../../../Icons/Update'
	import IconDownload from '../../../Icons/Download'
	import IconSettings from '../../../Icons/Settings'
	import IconArrow from '../../../Icons/Arrow'
	import IconTrash from '../../../Icons/Trash'
	import IconPdf from '../../../Icons/Pdf'
	import IconXls from '../../../Icons/Xls'
	import IconClose from '../../../Icons/Close'
    import LoaderHeaderList from '../../../PagesLoaders/HeaderList'
	import LoaderClocking from '../../../PagesLoaders/Clocking'

    export default {
    	components: {
            IconSearch,
			IconUpdate,
			IconDownload,
			IconSettings,
			IconArrow,
			IconTrash,
			IconPdf,
			IconXls,
			IconClose,
			LoaderHeaderList,
			LoaderClocking
        },
        data() {
            return {
            	loaded: false,
				loadedTable: false,
            	search_filter: '',
            	users: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				queryFiltersCount: 0,
            	extendFilters: false,

				filterUsers: [],
            	selectedUsers: [],
            	searchUsers: '',

				filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',

				filterDepartments: [],
            	selectedDepartments: [],
            	searchDepartments: '',

				filterWorkstations: [],
            	selectedWorkstations: [],
            	searchWorkstations: '',

				filterContractsType: [],
            	selectedContractType: [],
            	searchContractsType: '',

				filterContractPriodType: [],
            	selectedContractPeriodType: [],
            	searchContractPriodType: '',

				filterHoursPerDays: [],
				selectedHoursPerDay: [],
				searchHoursPerDay: '',

				filterWorkConditionTypes: [],
				selectedWorkConditionTypes: [],
				searchWorkConditionTypes: '',

                clocking_data: {},

				search: '',
				payroll_date: {},
				done: false,
				selectedMonth: moment(),
				months:[this.$t('calendar.1'), this.$t('calendar.2'), this.$t('calendar.3'), this.$t('calendar.4'), this.$t('calendar.5'), this.$t('calendar.6'), this.$t('calendar.7'), this.$t('calendar.8'), this.$t('calendar.9'), this.$t('calendar.10'), this.$t('calendar.11'), this.$t('calendar.12')]
            }
        },
        async mounted(){
			if(this.$route.params.data) {
				this.payroll_date = this.$route.params.data
				await this.getClocking()
			}

			await this.getFilters()

			setTimeout(() => {
				var title = this.$t('navbar.clocking');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refresClockingIndex', () => {
				this.getClocking()
			})
			this.$root.$on('refreshSalaryIndex', (data) => {
				this.payroll_date = data
				this.getClocking()
			})
			
        },
		beforeDestroy() {
            this.$root.$off('refresClockingIndex')
			this.$root.$off('refreshSalaryIndex')
        },
        watch: {
			search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
		computed: {
			filteredUsers(){
				return getByKeywordFilter(this.filterUsers, this.searchUsers)
			},
			filteredDepartments(){
				return getByKeywordFilter(this.filterDepartments, this.searchDepartments)
			},
			filteredWorkstations(){
				return getByKeywordFilter(this.filterWorkstations, this.searchWorkstations)
			},
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			filteredContractsType(){
				return getByKeywordFilter(this.filterContractsType, this.searchContractsType)
			},
			filteredContractPriodType(){
				return getByKeywordFilter(this.filterContractPriodType, this.searchContractPriodType)
			},
			filteredHoursPerDays() {
				return getByKeywordFilter(this.filterHoursPerDays, this.searchHoursPerDay)
			},
			filteredWorkConditionTypes() {
				return getByKeywordFilter(this.filterWorkConditionTypes, this.searchWorkConditionTypes)
			}
        },
        
        methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true, departments: true, workstations: true, roles: true, contract_types: true, contract_period_types: true, hours_per_day: true, work_condition_types: true}})
				.then(({data}) => {
					this.filterUsers = data.data.users
					this.filterRoles = data.data.roles
					this.filterDepartments = data.data.departments
					this.filterWorkstations = data.data.workstations
					this.filterContractsType = data.data.contract_types
					this.filterContractPriodType = data.data.contract_period_types
					this.filterHoursPerDays = data.data.hours_per_day.map(el => {return {
						id: el.hours_per_day, name: el.hours_per_day.toString()
					}})
					this.filterWorkConditionTypes = data.data.work_condition_types
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
            async getClocking(){
				var paramsCall = {}
				await this.queryObject(paramsCall)

                return axios.get( `/${this.$auth.user().instance.id}/payroll-timesheets`, {params: paramsCall})
				.then(({data}) => {
                    this.clocking_data = data.data
					this.loadedTable = true
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
            },

			async updateClocking() {
				await axios.post('/payroll-timesheet-calculation?reset=true')
				.then(({data}) => {
					this.getClocking();
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }

					if(key == 'users'){ paramsCall.user_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'departments'){ paramsCall.department_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'workstations'){ paramsCall.workstation_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_type_id'){ paramsCall.contract_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_period_type_id'){ paramsCall.contract_type_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'hours_per_days'){ paramsCall.hours_per_days = dataQuery[key].split(',').map(Number) }
					if(key == 'work_condition_type_id'){ paramsCall.work_condition_type_ids = dataQuery[key].split(',').map(Number) }
				});
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
					
				} else {
					if(this[filter].includes(parseInt(value))){
					
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
					
				}

				this.addFilterToRouteQuery(filter)
        	},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);
				
				if(filterName == 'selectedUsers' || filterName == 'all'){ dataQuery['users'] = this.selectedUsers.toString(); }
				if(filterName == 'selectedWorkstations' || filterName == 'all'){ dataQuery['workstations'] = this.selectedWorkstations.toString(); }
				if(filterName == 'selectedDepartments' || filterName == 'all'){ dataQuery['departments'] = this.selectedDepartments.toString(); }
				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedContractType' || filterName == 'all'){ dataQuery['contract_type_id'] = this.selectedContractType.toString(); }
				if(filterName == 'selectedContractPeriodType' || filterName == 'all'){ dataQuery['contract_period_type_id'] = this.selectedContractPeriodType.toString(); }
				if(filterName == 'selectedHoursPerDay' || filterName == 'all'){ dataQuery['hours_per_days'] = this.selectedHoursPerDay.toString(); }
				if(filterName == 'selectedWorkConditionTypes' || filterName == 'all'){ dataQuery['work_condition_type_id'] = this.selectedWorkConditionTypes.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedUsers = []
					this.selectedContractType = []
					this.selectedWorkstations = []
					this.selectedDepartments = []
					this.selectedRoles = []
					this.selectedContractPeriodType = []
					this.selectedHoursPerDay = []
					this.selectedWorkConditionTypes = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }

					if(key == 'users'){ this.selectedUsers = dataQuery[key].split(',').map(Number) }
					if(key == 'workstations'){ this.selectedWorkstations = dataQuery[key].split(',').map(Number) }
					if(key == 'departments'){ this.selectedDepartments = dataQuery[key].split(',').map(Number) }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_type_id'){ this.selectedContractType = dataQuery[key].split(',').map(Number) }
					if(key == 'contract_period_type_id'){ this.selectedContractPeriodType = dataQuery[key].split(',').map(Number) }
					if(key == 'hours_per_days'){ this.selectedHoursPerDay = dataQuery[key].split(',').map(Number) }
					if(key == 'work_condition_type_id'){ this.selectedWorkConditionTypes = dataQuery[key].split(',').map(Number) }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedTable = false
				this.clocking_data = []
				this.getClocking()
			},
			async confirmUpdate() {
				var buttonName = `buttonUpdate`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				if(this.$resize && (this.$mq.above(600) && !this.$mq.between([781, 1150]))){
					btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				}

				await axios.post('/payroll-timesheet-calculation')
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && (this.$mq.above(600) && !this.$mq.between([781, 1150]))){
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
						}
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							if(this.$resize && (this.$mq.above(600) && !this.$mq.between([781, 1150]))){
								btnSubmitText.innerHTML = this.$t('general.update')
							}
							btnSubmit.disabled = false
							this.getClocking()
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						if(this.$resize && (this.$mq.above(600) && !this.$mq.between([781, 1150]))){
							btnSubmitText.innerHTML = this.$t('btn-submit.error')
						}
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							if(this.$resize && (this.$mq.above(600) && !this.$mq.between([781, 1150]))){
								btnSubmitText.innerHTML = this.$t('general.save')
							}
							btnSubmit.disabled = false
							if(error.response) {
								if(error.response.status == 500) {
									alert(this.$t('error.500'))
								}
								if(error.response.data.errors.popup) {
									this.$root.$emit('open_modal', 'error', {data: error.response.data.errors.popup[0]}, false);
								}
							}
						}, 1000)
					}, 300)
				})
			},
			async donwloadXLS() {
				var paramsCall = {}
				await this.queryObject(paramsCall)

				return axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/xls/payroll-timesheets`, 
				{
					params: paramsCall,
                	responseType: 'blob'
				})
				.then((response) => {
					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();
				})
            },
			async donwloadPdf() {
				var paramsCall = {}
				await this.queryObject(paramsCall)

				await axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/payroll-timesheets?inline=true`,
				{	
					params: paramsCall,
				})
				.then((response) => {
					this.$root.$emit('open_modal', 'pdf', {data: response.data, from: 'payroll-timesheets', filters: paramsCall, date: this.payroll_date, text: this.$t('salary.download.clocking_pdf_text')}, false);
				})
            },
			async donwloadDailyPdf() {
				var paramsCall = {}
				await this.queryObject(paramsCall)

				return axios.get(`${this.$auth.user().instance.id}/payroll-reports/export/pdf/payroll-timesheet-days?inline=true`, 
				{
					params: paramsCall,
				})
				.then((response) => {
					this.$root.$emit('open_modal', 'pdf', {data: response.data, from: 'payroll-timesheet-days', filters: paramsCall, date: this.payroll_date, text: this.$t('salary.download.clocking_daily_pdf_text')}, false);
				})
			}
        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
.btn-tbf{
	&.custom {
		// border-end-end-radius: none;
		border-bottom-right-radius: initial;
		border-top-right-radius: initial;
		margin-right: 0 !important;
	}
}

.container-table {
	display: flex;
	.space-left{
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 105px;
		width: 105px;
		@media (max-width: 1024px) {
			flex: 0 0 70px;
		}
		@media (max-width: 780px) {
			flex: 0 0 40px;
		}
	}
	.space-right{
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 98px;
		width: 98px;
		@media (max-width: 1024px) {
			flex: 0 0 70px;
		}
		@media (max-width: 780px) {
			flex: 0 0 40px;
		}
	}
}

</style>